<template>
  <div>
    <div>
      <Row
        :gutter="8"
        class="m-b-10"
      >
        <!-- <i-col span="4">
          <Select
            v-model="query.type"
            placeholder="请选择变更类型"
            :clearable="true"
          >
            <Option
              v-for="item in changeType"
              :key="'type_'+item.value"
              :value="item.value"
            >{{item.name}}</Option>
          </Select>
        </i-col> -->
        <i-col span="6">
          <Input
            v-model.trim="query.queryString"
            placeholder="支持关键词查询，例如：客户、品牌、编码"
          />
        </i-col>
        <i-col span="6">
          <Button
            class="btn-black m-r-5"
            @click="initData"
          >搜索</Button>
          <Button
            type="success"
            @click="downloadModal=true"
          >下载列表</Button>
        </i-col>
      </Row>
      <Table stripe
        border
        :data="list"
        :columns="dataColumns"
        :height="clientHeight"
        :summary-method="handleSummary"
        show-summary
      ></Table>
    </div>
    <Modal
      v-model="downloadModal"
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >下载列表</p>
      <p
        style="color:red"
        class="p-b-10"
      >提示：默认选择日期为一个季度日期，可自由选择该季度内所有日期进行导出。</p>
      <Row :gutter="8">
        <i-col span="12">
          <label>开始日期：</label>
          <DatePicker
            type="date"
            :clearable="false"
            :options="dateOptions"
            v-model="dowloadModel.startDate"
            placeholder="选择开始日期"
            style="width: 200px"
          ></DatePicker>
        </i-col>
        <i-col span="12">
          <label>结束日期：</label>
          <DatePicker
            type="date"
            :clearable="false"
            :options="dateOptions"
            v-model="dowloadModel.endDate"
            placeholder="选择结束日期"
            style="width: 200px"
          ></DatePicker>
        </i-col>

      </Row>
      <div slot="footer">
        <Button
          type="success"
          @click="handleDownload"
        >导出</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { toMoney } from '@/utils/wnumb_own'
import { ParseDate } from '@/utils/dateFormat'
import { downloadFileRequest } from '@/utils/download'
import { listPublisherOffsetContractExecute } from '@/api/dw/verify'
export default {
  data () {
    return {
      query: {
        queryString: ''
      },
      dowloadModel: {
      },
      dateOptions: {},
      clientHeight: 0,
      executeTypeArray: new Map([
        [1, '系统生成'],
        [2, '系统调整'],
        [3, '人工调整']
      ]),
      list: [],
      total: 0,
      dataColumns: [
        { title: '编码', align: 'center', key: 'code' },
        { title: '客户', align: 'center', key: 'advertiserName' },
        { title: '品牌', align: 'center', key: 'name' },
        {
          title: '变更类型',
          align: 'center',
          render: (h, params) => {
            return h('span', this.executeTypeArray.get(params.row.executeType))
          }
        },
        {
          title: '原因',
          align: 'center',
          render: (h, params) => {
            return h('span', params.row.executeRemark)
          }
        },
        { title: '产生日期', align: 'center', key: 'executeDate' },
        {
          title: '发布费',
          align: 'center',
          key: 'signUsePrice',
          render: (h, params) => {
            return h('span', toMoney(params.row.signUsePrice))
          }
        },
        {
          title: '制作费',
          align: 'center',
          key: 'producePrice',
          render: (h, params) => {
            return h('span', toMoney(params.row.producePrice))
          }
        },
        {
          title: '上刊费',
          align: 'center',
          key: 'installPrice',
          render: (h, params) => {
            return h('span', toMoney(params.row.installPrice))
          }
        },
        {
          title: '小计',
          align: 'center',
          key: 'subtotal',
          render: (h, params) => {
            return h('span', toMoney(params.row.subtotal))
          }
        },
        {
          title: '操作',
          align: 'center',
          render: (h, param) => {
            return h('a', {
              on: {
                click: () => {
                  this.$store.commit('set_reconciliation_pageType', 'statementContractDetail2')
                  this.$store.commit('set_reconciliation_contractQuery', { id: param.row.subjectId, versionId: param.row.versionId })
                }
              }
            }, '详情')
          }
        }
      ],
      downloadModal: false

    }
  },
  created () {
    const that = this
    that.query.companyId = this.bill.companyId
    that.query.publisherId = this.bill.publisherId
    that.query.startDate = this.bill.startDate
    that.query.endDate = this.bill.endDate
    that.dowloadModel.startDate = this.bill.startDate
    that.dowloadModel.endDate = this.bill.endDate
    that.initData()
    that.clientHeight = document.documentElement.clientHeight - 260

    that.dateOptions = {
      disabledDate (date) {
        return (date && date.valueOf() < new Date(that.query.startDate).valueOf() - 86400000) || (date && date.valueOf() > new Date(that.query.endDate).valueOf())
      }
    }
  },
  computed: {
    bill () {
      return this.$store.state.reconciliation.bill
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.onresize = () => {
        this.clientHeight = document.documentElement.clientHeight - 260
      }
    })
  },
  methods: {
    initData () {
      // this.query.type = this.query.type || null
      listPublisherOffsetContractExecute(this.query).then(res => {
        if (res && !res.errcode) {
          this.list = res.map(x => {
            const subtotal = x.signUsePrice + x.producePrice + x.installPrice
            return Object.assign(x, { subtotal })
          })
        } else {
          this.list = []
        }
      })
    },
    handleSummary ({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '合计'
          }
          return
        }
        const values = data.map(item => Number(item[key]))
        if (!values.every(value => isNaN(value))) {
          const v = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          sums[key] = {
            key,
            value: toMoney(v)
          }
        } else {
          sums[key] = {
            key,
            value: '-'
          }
        }
      })

      return sums
    },
    handleDownload () {
      const query = {
        companyId: this.query.companyId,
        publisherId: this.query.publisherId,
        startDate: ParseDate(this.dowloadModel.startDate),
        endDate: ParseDate(this.dowloadModel.endDate),
        queryString: this.query.queryString
      }
      const filename = `权益额调整项明细（${ParseDate(this.dowloadModel.startDate)}至${ParseDate(this.dowloadModel.endDate)}）.xls`
      downloadFileRequest(process.env.VUE_APP_API_URL_V2 + '/ooh-dw/v1/dw/verify/exportPublisherOffsetContractExecute', query, filename)
    }
  }
}
</script>
